<template>
  <div id="centennialOverlay">
    <div class="o-modal-overlay is-visible" v-if="!closeModel">
      <div class="o-modal is-visible">
        <div class="o-modal__wrapper">
          <a class="o-modal__close" href="#" data-behavior="ModalClose" @click="closeModal">
            <svg class="icon icon--close close-button" aria-hidden="true">
              <use xlink:href="./assets/app.svg#close-video-icon" />
            </svg>
          </a>
          <div class="ctaBtn" @click="openLink">
            <a
              :href="ctalink ? ctalink : 'https://www.newschool.edu/festival-of-new/'"
            >Register and build your schedule!</a>
          </div>
          <img
            class="lightbox-image"
            data-role="lightbox_image desktop_centennaial"
            style="width: 100%;"
            :src="screenSize ? 'https://www.newschool.edu/searchmodule/centennial/FA19WEB-FestivalWebIntervention_925.jpg' : 'https://www.newschool.edu/searchmodule/centennial/FON_web_intervention925.gif' "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import closeBtn from '~/assets/app.svg'
// import fontscss from '@/assets/resource/font/font.css'

export default {
  name: "centennialOverlay",
  data() {
    return {
      closeModel: false,
      screenSize: false
    };
  },
  props: ["ctalink"],

  beforeMount() {
    window.addEventListener("resize", this.onResize);
    const localb = localStorage.getItem("_tns_centennial_overlay");
    //const d48 = if(localb  Date.now()  - (48 * 60 * 60 * 1000)
    const d48 =
      localb && localb >= Date.now() - 48 * 60 * 60 * 1000 ? true : false;
    this.closeModel = d48;
  },
  methods: {
    openLink() {
      const $g = this;
      localStorage.setItem("_tns_centennial_overlay", Date.now());
      window.open(
        $g.ctalink ? $g.ctalink : "https://www.newschool.edu/festival-of-new/",
        "_self"
      );
    },
    onResize() {
      this.screenSize = window.matchMedia(
        "(max-width: 480px) and (-webkit-min-device-pixel-ratio: 2)"
      ).matches;
    },
    closeModal() {
      localStorage.setItem("_tns_centennial_overlay", Date.now());
      this.closeModel = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.modal__body,
.modal__header {
  display: flex;
  width: 100%;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
}
.modal__close {
  background: transparent;
  border: none;
  width: 50px;
  height: 50px;
  width: 70px;
  height: 70px;
  .close-button {
    fill: #000;
  }
}
.o-modalHidden {
  display: none;
}

.o-modal {
  background: transparent;
  position: fixed;
  -webkit-transform: none;
  transform: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
  margin-right: auto;
  margin-left: auto;
  overflow-y: hidden;
  display: none;
  opacity: 0;
  visibility: hidden;
  min-height: inherit;
}

.o-modal iframe {
  position: relative;
  width: 100%;
  height: 100%;
}
.o-modal .o-modal__close .icon {
  width: 65px;
  height: 65px;
}
.o-modal .o-modal__close {
  position: absolute;
  top: 20px;
  z-index: 1000000;
  svg {
    border: none;

    width: 65px;
    height: 65px;
    .close-button {
      fill: #000;
    }
  }
}

.o-modal.is-visible {
  display: block;
  opacity: 1;
  visibility: visible;
  display: flex;
  align-items: center;
}

.o-modal.size400x650,
.o-modal.size400x650 .o-modal__wrapper {
  padding: 0;
}

.o-modal .o-modal__close {
      right: 35px;
      @media screen and (max-width: 800px) {
          right: 25px;
      }
}
@media screen and (min-width: 30em) {
  .o-modal {
    overflow-y: auto;
    height: auto;
    max-width: 1200px;
    padding: 0 0px;
    min-height: auto;
    top: 50%;
    left: 50%;
    -webkit-transform: translate3D(-50%, -50%, 0);
    transform: translate3D(-50%, -50%, 0);
  }

  .o-modal.size400x650 {
    width: 400px;
    height: 650px;
  }

  .o-modal.size400x650 .o-modal__wrapper {
    height: 100%;
    width: 100%;
  }

  .o-modal.width600 {
    width: 600px;
  }

  .o-modal.width700 {
    width: 700px;
  }

  .o-modal.skinny {
    width: 50%;
  }

  .o-modal.large iframe {
    height: 800px;
  }

  .o-modal.small iframe {
    height: 400px;
  }
}

@media screen and (min-width: 61.25em) {
  .o-modal {
    overflow-y: scroll;
  }
}

.o-modalContent {
  display: none;
}

.is-visible .o-modalContent {
  display: block;
}

.o-modal--homeAlert {
  z-index: 90;
  max-width: none;
  background-color: #f3f1ef;
  top: 60px;
  left: 0;
  height: 100%;
  -webkit-transform: none;
  transform: none;
  color: #191919;
  padding: 0;
}

@media screen and (min-width: 40em) {
  .o-modal--homeAlert {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (min-width: 61.25em) {
  .o-modal--homeAlert {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
    top: 140px;
  }

  .o-modal--homeAlert .row.column > *,
  .o-modal--homeAlert .row.columns > * {
    width: 87.66%;
  }
}

@media screen and (min-width: 72.5em) {
  .o-modal--homeAlert {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media screen and (min-width: 90.625em) {
  .o-modal--homeAlert {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.o-modal--homeAlert .o-modal__close {
  right: 15px;
}

@media screen and (min-width: 40em) {
  .o-modal--homeAlert .o-modal__close {
    right: 20px;
  }
}

@media screen and (min-width: 61.25em) {
  .o-modal--homeAlert .o-modal__close {
    right: 45px;
  }
}

.o-modal--homeAlert.o-modal-overlay {
  display: none;
}

.o-modal--homeAlert .o-modal__wrapper {
  background-color: rgba(0, 0, 0, 0.45);
  padding: 60px 0 120px;
}

.o-modal--homeAlert .o-modal__wrapper div > a {
  font-family: neue;
  font-size: 13px;
  line-height: 20px;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0;
  color: #e42a1d;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 20px;
  display: block;
}

.o-modal--homeAlert h2 {
  font-size: 33px;
  line-height: 38px;
  font-weight: 300;
  margin-top: 25px;
  margin-bottom: 15px;
}

.o-modal--homeAlert h2,
.o-modal--homeAlert h3 {
  font-family: neue;
  font-style: normal;
  letter-spacing: 0;
}

.o-modal--homeAlert h3 {
  font-size: 12px;
  line-height: 14px;
  font-weight: 900;
  color: #e42a1d;
  text-transform: uppercase;
  margin-top: 23px;
}

.o-modal--homeAlert p {
  font-family: neue;
  font-size: 17px;
  line-height: 28px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0;
  margin-top: 10px;
}

.o-modal--video .o-modal__wrapper {
  padding: 56.25% 0 0;
  background: transparent;
}

.o-modal--video iframe {
  position: absolute;
  top: 0;
  left: 0;
}

.o-modal--video .o-modal__close {
  left: 0;
  top: calc(50% - 28.125vw - 35px);
}

.o-modal--video .o-modal__close svg {
  fill: #fff;
}

@media screen and (min-width: 30em) {
  .o-modal--video .o-modal__close {
    left: 105px;
    top: 5px;
  }
}

.o-modal__wrapper {
  // background-color: #fff;
  padding: 0;
  height: auto;
}

@media screen and (min-width: 30em) {
  .o-modal__wrapper {
    height: auto;
    width: 98vw;
    margin: auto;
  }
}

.o-modal-overlay {
  background-color: rgba(0, 0, 0, 0.45);
  opacity: 0;
  transition: opacity 0.25s;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 100%;
  right: 100%;
}

@media screen and (min-width: 61.25em) {
  .o-modal-overlay {
    transition: none;
  }
}

.o-modal-overlay:before {
  content: "";
  position: absolute;
  left: 50%;
  width: 100vw;
  height: 100%;
  margin-left: -50vw;
  background-color: inherit;
}

.o-modal-overlay.is-visible {
  height: 100%;
  opacity: 1;
  width: 100vw;
  z-index: 100;
}

html.s-modal-active {
  height: 100%;
  overflow: hidden;
}
.lightbox_image {
  width: 100% !important;
}
.ctaBtn {
  display: none;
  cursor: pointer;
}
@media screen and (min-width: 550px) {
  .ctaBtn {
    height: 7vw;
    max-height: 120px;
    width: 33%;
    cursor: pointer;
    position: absolute;
    bottom: 7.3%;
    right: 0;
    margin-right: 9%;
    display: block;
    a {
      display: none;
      font-size: 35px;
      // font-family: Neue-Regular;
      color: #f3f1ef;
      max-width: 20vh;
      text-decoration: none;
      transition: all 0.3s ease-in-out;
      &:hover {
        color: #ed2230;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .o-modal__close {
    top: 20px;
    z-index: 1000000;
    right: 20px;
    .icon {
      width: 45px !important;
      height: 45px !important;
    }
  }
  .o-modal__wrapper {
    padding: 5px;
  }
  .close-button {
    fill: #fff;
  }
  .ctaBtn {
    cursor: pointer;
    animation: 3s ease 0s normal forwards 1 fadein;
    margin-top: 53.0%;
    right: 10%;
    bottom:initial;
    position: absolute;
    display: block;
    height: 34px;
    width: 136px;
    
    a {
      display: none;
      font-size: 10px;
      font-family: Neue-Regular;
      color: #f3f1ef;
      width: 12vw;
      text-decoration: none;
      transition: all 0.3s ease-in-out;
      &:hover {
        color: #ed2230;
      }
    }
  }
}
</style>
